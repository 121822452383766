import React from 'react'
import Helmet from 'react-helmet'
import Navigation from './Navigation'
import Footer from './Footer'
import '../css/style.css'
import LayoutAnnouncement from './LayoutAnnouncement'

const naviItems = ['Aktuelles', 'Verein', 'Ausbildung', 'Kontakt']

export default props => (
  <>
    {/* <LayoutAnnouncement /> */}
    <div className="max-w-3xl mx-auto flex flex-col justify-between min-h-screen">
      <Helmet>
        <title>Musikverein Trachtenkapelle Winterbach e.V.</title>
        <script
          async
          src="https://cdn.panelbear.com/analytics.js?site=DCQxwhYYIiG"
        />
        <script>{`
		    window.panelbear = window.panelbear || function(){ window.panelbearQ = window.panelbearQ || []; panelbearQ.push(arguments); };
		    panelbear('config', { site: 'DCQxwhYYIiG' });
		`}</script>
      </Helmet>
      <Navigation items={naviItems} />
      <div className="mx-6 md:mx-12 mt-12 mb-auto">{props.children}</div>
      <Footer className="mt-20" />
    </div>
  </>
)
