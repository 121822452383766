import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export default props => {
  const data = useStaticQuery(query)
  return (
    <div className={props.className + ' text-xs text-grey'}>
      🔨 Diese Seite wird regelmäßig weiterentwickelt. Letzte Änderung:{' '}
      {data.site.buildTime}
    </div>
  )
}

const query = graphql`
  query info {
    site {
      buildTime(formatString: "DD.MM.YYYY")
    }
  }
`
